import React, { useEffect, useState, useMemo } from "react";
import { Input, Modal, Icon } from "semantic-ui-react";

// UI ADM
import CardBedUX from "react-lib/apps/HISV3/ADM/CardBedUX";
import CardAdmit from "react-lib/apps/HISV3/ADM/CardAdmit";
import ModCancelAdmitOrder from "react-lib/apps/HISV3/ADM/ModCancelAdmitOrder";
import CardAdmitChange from "./CardAdmitChange";

// UI TPD
import CardPatientSearchBox from "react-lib/apps/HISV3/TPD/CardPatientSearchBox";

// Common
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ModInfo from "react-lib/apps/common/ModInfo";

// Utils
import { KEY_CODE } from "react-lib/utils/constant";
import { useIntl } from "react-intl";

// Constant
const CARD_RESERVE_BED = "CardReserveBed";
const MOD_RESERVE_BED = "ModReserveBed";

const CardBed = (props: any) => {
  const intl = useIntl();
  const [page, setPage] = useState<number>(1);
  const [openRoomReserve, setOpenRoomReserve] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<{
    type: "room_admit" | "cancel_reserve" | "cancel_admit";
    title: string;
  } | null>(null);
  const [openAdmitChange, setOpenAdmitChange] = useState<
    "RESERVE_TRANSFER" | "CHANGE_BED" | ""
  >("");
  const [openMessage, setOpenMessage] = useState(false);

  useEffect(() => {
    props.runSequence({ sequence: "SetBed", restart: true });
    return () => {
      props.runSequence({ sequence: "SetBed", clear: true });
    };
  }, []);

  useEffect(() => {
    const updateData = async () => {
      const id = props.SetBedSequence?.selectedRoom?.id;
      const roomList = props.SetBedSequence?.roomList || [];

      if (id && roomList?.[0]) {
        const findRow = roomList?.find((item: any) => id === item.id);
        props.setProp(`SetBedSequence.selectedRoom`, findRow || null);
      }
    };

    updateData();
  }, [props.SetBedSequence?.roomList]);

  useEffect(() => {
    const handleClose = () => {
      if (props.buttonLoadCheck?.CardAdmitChange === "SUCCESS") {
        props.setProp(`errorMessage.CardAdmitChange`, null);
        props.runSequence({
          sequence: "SetBed",
          action: "refresh",
          page: page,
        });

        handleCloseAdmitChange();
      }
    };

    handleClose();
  }, [props.buttonLoadCheck?.CardAdmitChange]);

  useEffect(() => {
    if (
      props.errorMessage?.CardReserveBed !== null &&
      props.errorMessage?.CardReserveBed !== undefined
    ) {
      setOpenConfirm(null);
      setOpenMessage(true);
    }
  }, [props.errorMessage?.CardReserveBed]);

  const roomList = useMemo(() => {
    return (props.SetBedSequence?.roomList || []).map((item: any) => ({
      ...item,
      room_status: <div>{item.is_reserve ? "จอง" : item.room_status}</div>,
    }));
  }, [props.SetBedSequence?.roomList]);

  const changeSetRoom = (key: string) => (e: any, v: any) => {
    const value = typeof v.checked === "boolean" ? v.checked : v.value;

    props.setProp(`SetBedSequence.${key}`, value);
  };

  const handleChangePage = (page: number) => {
    props.runSequence({
      sequence: "SetBed",
      action: "search",
      page: page,
    });
    setPage(page);
  };

  const handleGetRoomList = (params: any) => {
    return props.onEvent({ message: "GetListRoom", params });
  };

  const handleGetAdmitOrderList = (params: any) => {
    return props.onEvent({ message: "GetListAdmitOrder", params });
  };

  const handleCloseRoomReserve = () => {
    setOpenRoomReserve(false);
  };

  const handleSaveReserve = (params: any) => {
    props.runSequence({
      sequence: "SetBed",
      action: "room_reserve",
      page: page,
      card: CARD_RESERVE_BED,
      ...params,
      onSuccess: handleCloseRoomReserve,
    });
    // handleCloseRoomReserve();
  };

  const handleConfirm = (note: string) => {
    if (openConfirm?.type === "room_admit") {
      props.runSequence({
        sequence: "SetBed",
        action: "room_admit",
        page: page,
        card: CARD_RESERVE_BED,
      });
    } else if (openConfirm?.type === "cancel_reserve") {
      props.runSequence({
        sequence: "SetBed",
        action: "cancel_reserve",
        page: page,
        card: CARD_RESERVE_BED,
        note,
      });
    } else if (openConfirm?.type === "cancel_admit") {
      props.runSequence({
        sequence: "SetBed",
        action: "cancel_admit",
        page: page,
        card: CARD_RESERVE_BED,
        note,
      });
    }
  };

  const handleCloseModConfirm = () => {
    setOpenConfirm(null);
    props.setProp(`errorMessage.${CARD_RESERVE_BED}`, null);
  };

  const handleChangePatient = (
    id: any,
    hn: string,
    full_name: string,
    birthdate: string
  ) => {
    if (hn) {
      props.setProp(`SetBedSequence`, { ...props.SetBedSequence, inputHN: hn });
    }
  };

  const handleSearch = () => {
    props.runSequence({ sequence: "SetBed", action: "search" });
    setPage(1);
  };

  const handleInputChange = (hn: string) => {
    props.setProp(`SetBedSequence.inputHN`, hn);
  };

  const handleCancelAdmit = () => {
    setOpenConfirm({
      type: "cancel_admit",
      title: "ต้องการยกเลิก Admit Order หรือไม่",
    });
  };

  const handleCancelReserve = () => {
    setOpenConfirm({
      type: "cancel_reserve",
      title: "ต้องการยกเลิกการจองหรือไม่",
    });
  };

  const handleRoomAdmit = () => {
    setOpenConfirm({
      type: "room_admit",
      title: "ต้องการรับผู้ป่วยเข้าหรือไม่",
    });
  };

  // ---------- Bed Move
  const handleOpenAdmitChange =
    (action: "RESERVE_TRANSFER" | "CHANGE_BED") => () => {
      setOpenAdmitChange(action);
    };

  const handleCloseAdmitChange = () => {
    setOpenAdmitChange("");
  };

  const handleCloseMessage = () => {
    setOpenMessage(false);
    props.setProp(`errorMessage.CardReserveBed`, null);
  };

  const handlePrint = () => {
    console.log("onPrint !! ", props.SetBedSequence?.roomList);
    if (props.SetBedSequence?.roomList?.length === 0) {
      props.runSequence({
        sequence: "SetBed",
        action: "search",
        withPrint: "printBedReport",
      });
      setPage(1);
    } else {
      props.runSequence({
        sequence: "SetBed",
        action: "printBedReport",
      });
    }
  };

  return (
    <>
      <CardBedUX
        // Report
        showPrintBtn={props.showPrintBtn}
        hiddenCardBedAction={props.hiddenCardBedAction}
        onPrint={handlePrint}
        // Admit order data
        admitOrderList={props.SetBedSequence?.admitOrderList || []}
        selectedAdmitOrder={props.SetBedSequence?.selectedAdmitOrder}
        orderRowProps={(state: any, rowInfo: any, column: any, instance: any) => ({
          style: {
            backgroundColor:
              rowInfo?.original?.id &&
              rowInfo?.original?.id === props.SetBedSequence?.selectedAdmitOrder?.id
                ? "#cccccc"
                : "white",
          },
          onClick: () => props.setProp(`SetBedSequence.selectedAdmitOrder`, rowInfo?.original),
        })}
        disableAdmit={
          !Number.isInteger(props.SetBedSequence?.selectedAdmitOrder?.id) ||
          props.SetBedSequence?.selectedAdmitOrder?.room_no === ""
        }
        // Room data
        isHN={props.SetBedSequence?.isHN ?? false}
        inputHN={props.SetBedSequence?.inputHN || ""}
        selectedRoomType={props.SetBedSequence?.selectedRoomType || "all"}
        roomTypeOptions={
          props.masterOptions?.roomType?.concat([
            { key: "all", text: "แสดงทุกประเภทห้อง", value: "all" },
          ]) || []
        }
        selectedWardType={props.SetBedSequence?.selectedWardType || "all"}
        wardTypeOptions={
          props.masterOptions?.wardType?.concat([
            { key: "all", text: "แสดงทุกประเภทหอผู้ป่วย", value: "all" },
          ]) || []
        }
        selectedDivision={props.SetBedSequence?.selectedDivision || "all"}
        divisionOptions={
          props.masterOptions?.divisionIpd?.concat([
            { key: "all", text: "แสดงทุกหน่วยตรวจ", value: "all" },
          ]) || []
        }
        selectedRoomStatus={props.SetBedSequence?.selectedRoomStatus || "all"}
        roomStatusOptions={
          props.masterOptions?.roomStatus?.concat([
            { key: "all", text: "แสดงทุกสถานะห้อง", value: "all" },
          ]) || []
        }
        roomList={roomList}
        roomRowProps={(state: any, rowInfo: any, column: any, instance: any) => ({
          style: {
            backgroundColor:
              rowInfo?.original?.id &&
              rowInfo?.original?.id === props.SetBedSequence?.selectedRoom?.id
                ? "#cccccc"
                : "white",
          },
          onClick: () =>
            props.setProp(
              `SetBedSequence.selectedRoom`,
              props.SetBedSequence?.roomList?.[rowInfo.index]
            ),
        })}
        // Methods
        searching={props.SetBedSequence?.searching ?? false}
        hideAdmitOrder={props.hideAdmitOrder}
        // config
        config={{
          disableRoomReserve: !(
            Number.isInteger(props.SetBedSequence?.selectedAdmitOrder?.id) &&
            Number.isInteger(props.SetBedSequence?.selectedRoom?.id) &&
            props.SetBedSequence?.selectedRoom?.room_status === "ว่าง" &&
            !props.SetBedSequence?.selectedRoom?.is_reserve
          ),
          disableRoomAdmit: !(
            Number.isInteger(props.SetBedSequence?.selectedRoom?.id) &&
            props.SetBedSequence?.selectedRoom?.is_reserve
          ),
          disableCancelReserve: !(
            Number.isInteger(props.SetBedSequence?.selectedRoom?.id) &&
            props.SetBedSequence?.selectedRoom?.is_reserve
          ),
          disableCancelAdmit: !(
            Number.isInteger(props.SetBedSequence?.selectedRoom?.id) &&
            props.SetBedSequence?.selectedRoom?.room_status === "Admit"
          ),
          disableRoomTransfer:
            !Number.isInteger(props.SetBedSequence?.selectedAdmitOrder?.id) ||
            !Number.isInteger(props.SetBedSequence?.selectedRoom?.id),
          disableBedMove: !(
            Number.isInteger(props.SetBedSequence?.selectedAdmitOrder?.id) &&
            Number.isInteger(props.SetBedSequence?.selectedRoom?.id) &&
            props.SetBedSequence?.selectedRoom?.can_reserve
          ),
          disableWardMove: !(
            Number.isInteger(props.SetBedSequence?.selectedAdmitOrder?.id) &&
            Number.isInteger(props.SetBedSequence?.selectedRoom?.id) &&
            props.SetBedSequence?.selectedRoom?.can_reserve
          ),
          hideBedMove: true,
          hideWardMove: true,
          ...(props.config || {}),
        }}
        // callback
        onAdmit={() => props.runSequence({ sequence: "SetBed", action: "admit" })}
        changeSetRoom={changeSetRoom}
        onSearch={handleSearch}
        onRoomReserve={() => setOpenRoomReserve(true)}
        onRoomTransfer={() => {}}
        onRoomAdmit={handleRoomAdmit}
        onCancelReserve={handleCancelReserve}
        onCancelAdmit={handleCancelAdmit}
        onBedMove={handleOpenAdmitChange("CHANGE_BED")}
        onMoveWard={handleOpenAdmitChange("RESERVE_TRANSFER")}
        // Element
        paginationBed={
          <CardBedPagination
            page={page}
            onInputPage={setPage}
            onPageChange={handleChangePage}
            totalPage={props.SetBedSequence?.totalPage}
          />
        }
        PatientSearchBox={
          props.drugOrderQueueController ? (
            <CardPatientSearchBox
              // controller
              controller={props.drugOrderQueueController}
              // data
              value={props.SetBedSequence?.inputHN}
              // callback
              onEnterPatientSearch={handleChangePatient}
              onInputChange={handleInputChange}
            />
          ) : (
            <Input
              onChange={changeSetRoom("inputHN")}
              size="mini"
              value={props.SetBedSequence?.inputHN || ""}
            ></Input>
          )
        }
        languageUX={props.languageUX}
      />

      <Modal open={openRoomReserve} onClose={handleCloseRoomReserve} closeOnDimmerClick>
        <CardAdmit
          setProp={props.setProp}
          // Controller
          drugOrderQueueController={props.drugOrderQueueController}
          // data
          roomNo={props.SetBedSequence?.selectedRoom?.room_no || ""}
          patientInfo={props.patientInfo}
          masterOptions={props.masterOptions}
          reserveCode={props.reserveCode}
          buttonLoadCheck={props.buttonLoadCheck}
          // Callback
          getRoomList={handleGetRoomList}
          getAdmitOrderList={handleGetAdmitOrderList}
          onSave={handleSaveReserve}
          languageUX={props.languageUX}
        />
      </Modal>

      <ModInfo
        open={!!props.successMessage?.[CARD_RESERVE_BED]}
        titleName={intl.formatMessage({ id: "บันทึกสำเร็จ" })}
        titleColor="green"
        onApprove={() => props.setProp(`successMessage.${CARD_RESERVE_BED}`, false)}
      />

      <ModInfo
        className="fixNotMiddleModal"
        // @ts-ignore
        testid="modError"
        open={openMessage}
        size="tiny"
        titleColor="red"
        titleName={intl.formatMessage({ id: "แจ้งเตือน" })}        alertText={
          <div style={{ margin: "20px 0px 10px" }}>
            {props.errorMessage?.CardReserveBed?.action?.encounter}
          </div>
        }
        onApprove={handleCloseMessage}
        onCloseWithDimmerClick={handleCloseMessage}
      />

      <ModCancelAdmitOrder
        open={!!openConfirm}
        title={openConfirm?.title}
        loading={props.buttonLoadCheck?.CardReserveBed_room_admit === "LOADING" || false}
        errorMessage={props.errorMessage}
        successMessage={props.successMessage}
        card={CARD_RESERVE_BED}
        // config
        type={openConfirm?.type}
        // callback
        onApprove={handleConfirm}
        onDeny={handleCloseModConfirm}
        languageUX={props.languageUX}
      />

      <Modal open={!!openAdmitChange} closeOnDimmerClick onClose={handleCloseAdmitChange}>
        <CardAdmitChange
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          // controller
          drugOrderQueueController={props.drugOrderQueueController}
          //  seq
          runSequence={props.runSequence}
          AdmitChangeSequence={props.AdmitChangeSequence}
          // data
          buttonLoadCheck={props.buttonLoadCheck}
          errorMessage={props.errorMessage}
          searchedItemList={props.searchedItemList}
          action={openAdmitChange}
          selectedHN={props.SetBedSequence?.selectedAdmitOrder?.hn}
          roomNo={props.SetBedSequence?.selectedRoom?.room_no}
          // options
          masterOptions={props.masterOptions}
          // callback
          getRoomList={handleGetRoomList}
          languageUX={props.languageUX}
        />
      </Modal>

      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.CardAdmitChange`, null);
        }}
        error={!props.ORRequestSequence?.modConfirm ? props.errorMessage?.CardAdmitChange : null}
        success={null}
        languageUX={props.languageUX}
      />
    </>
  );
};

const CardBedPagination = React.memo((props: any) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Icon
        name="caret left"
        size="big"
        onClick={() => {
          props.page === 1
            ? props.onPageChange(1)
            : props.onPageChange(props.page - 1);
        }}
      />
      <Input
        style={{ paddingRight: "10px", width: "60px" }}
        value={props.page}
        onChange={(e) => {
          props.onInputPage(e.target.value);
        }}
        onKeyDown={(event: any) => {
          if (event.keyCode === KEY_CODE.ENTER) {
            props.onPageChange(props.page);
          }
        }}
      />
      / <span>{props.totalPage || 1}</span>
      <Icon
        name="caret right"
        size="big"
        onClick={() => {
          props.page === props.totalPage
            ? props.onPageChange(props.totalPage)
            : props.onPageChange(props.page + 1);
        }}
      />
    </div>
  );
});

export default React.memo(CardBed);
